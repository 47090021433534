<template>
  <content id="sobremim" class="sombra-padrao">
    <div class="container-texto">
      <h2 class="destaque">Sobre mim</h2>      
      <p>Sou apaixonado por tecnologia, futebol, economia e piadas ruins.</p>
      <p>
        Tenho 4 anos de experiência em atendimento ao cliente e 2 anos atuando como assistente financeiro. 
        Durante a pandemia, decidi me dedicar à minha verdadeira paixão: o desenvolvimento de software. 
        Desde então, tenho buscado aprender cada dia mais sobre softwares e tecnologia.
      </p>
      <p>
        Me considero uma pessoa determinada, extrovertida e que ama trabalhar em equipe. 
        Acredito que o desenvolvimento de software é, acima de tudo, sobre pessoas e que devemos sempre buscar 
        solucionar problemas da forma mais simples possível.
      </p>
      <p>
        Atualmente, atuo há {{ experiencia }} como desenvolvedor de software backend. 
        As principais tecnologias que utilizo no meu trabalho são: C# (CSharp), .NET Framework 4.6.x, ASP.NET, MySQL, JavaScript e jQuery. 
        No entanto, tenho estudado sobre .NET Core e Vue.js para expandir meus conhecimentos.
      </p>
    </div>
  </content>
</template>
  
  <script>
  export default {
    name: 'SobreMim',
    data() {
      return {
        experiencia: 0
      }
    },
    methods:{
        obterExperiencia() {
          let hoje = new Date();
          let incioDeCarreira = new Date('02/02/2022');
          let diferenca = hoje.getTime() - incioDeCarreira.getTime();
          let meses = Math.floor(diferenca / (1000 * 60 * 60 * 24 * 30));
          if(meses > 12) {
            this.experiencia = `${Math.floor(meses / 12)} ${meses > 24 ? 'anos' : 'ano'} e ${meses % 12} ${meses % 12 > 1 ? 'meses' : 'mes'} `;
          } else {
            this.experiencia = `${meses} meses`;
          }
        }
    },
     created() {
      this.obterExperiencia()
    }
  }
  </script>
  
  <style>
  
  </style>
